import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectCard from "../components/ProjectCard"

const Projects = ({ data }) => {
    const projects = data.allMarkdownRemark.edges
    return (
        <Layout>
            <SEO title="Projects" />
            <p>Projects</p>
            <ul>
                {projects.map(({ node }) => {
                    const { title } = node.frontmatter
                    return (
                        <li key={node.id}>
                            <ProjectCard project={node} />
                        </li>
                    )
                })}
            </ul>
        </Layout>
    )
}

export default Projects

export const query = graphql`
    query ALL_PROJECTS {
        allMarkdownRemark {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`
